<template>
  <base-section
    id="service-and-components"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="700"
    >
      <base-info-title
        align="center"
        dark
        v-bind="getTitle"
      />
    </v-responsive>

    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container>
        <v-row justify="space-around">
          <v-col
            v-for="card in getCards"
            :key="card.title"
            cols="12"
            sm="4"
            md="4"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>

    <a name="get-started-now"></a>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionServiceAndComponents',

    computed: {
      getTitle () {
        return [
          {
            title: this.$t('componentTitle'),
            text: this.$t('componentSubTitle'),
          },
        ]
      },

      getCards () {
        return [
          {
            icon: 'mdi-keyboard-outline',
            title: this.$t('componentReason1Title'),
            text: this.$t('componentReason1Content'),
          },
          {
            icon: 'mdi-camera-outline',
            title: this.$t('componentReason2Title'),
            text: this.$t('componentReason2Content'),
          },
          {
            icon: 'mdi-pencil-outline',
            title: this.$t('componentReason3Title'),
            text: this.$t('componentReason3Content'),
          },
          {
            icon: 'mdi-camera-outline',
            title: this.$t('componentReason4Title'),
            text: this.$t('componentReason4Content'),
          },
          {
            icon: 'mdi-puzzle-outline',
            title: this.$t('componentReason5Title'),
            text: this.$t('componentReason5Content'),
          },
        ]
      },
    },
  }
</script>
